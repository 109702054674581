<template>
  <div class="search-bar-video">
    <div class="search-bar">
      <TreeSelect 
        class="device" 
        v-model="selectedDevices" 
        :title="$t('search_device') /*設備*/"
        :data="deviceTreeList" 
        :groupList="groupList"  
        :isGroupSelect="true"
        :isMultipleSelect="true"
        optionWidth="150%"
      />
      <TreeSelect 
        class="device" 
        v-model="selectedGroup" 
        :title="$t('history_device_group') /*設備群組*/"
        :data="deviceGroupList" 
        :groupList="groupList"  
        :isGroupSelect="true"
        :isMultipleSelect="false"
        :defaultProps="deviceGroupProps"
        optionWidth="150%"
      />
    
      <DateRangeSelect 
        class="dt-select" 
        v-model="videoDateTime" 
        :range="true"
        :title="$t('search_time_interval')" 
        :maxRangeDays="maxRangeDays"
        :enabledDateStart="enabledDateStart" 
        :enabledDateEnd="enabledDateEnd" 
      />
      <div class="search-btn" :class="{loading: searchLoading}" @click="searchVideo()">
        <div v-if="searchLoading" class="ring"></div>
      </div>
      <TooltipElement :content="$t('search_clear')/*清除*/" alwaysShow placement="bottom">
        <div class="tool" @click="earseSearchParams">
          <img src="@/assets/icons/history-erase.svg" alt="">
        </div>
      </TooltipElement>
      <div 
        class="download-btn" 
        :class="{ disabled: checkCount === 0 }" 
        @click="downloadVideo">
        <img src="@/assets/icons/export-events.svg" alt="">
        {{ $t('download_video')/*影片下載*/ }}
      </div>
    </div>

    <div v-show="historyVideoList.length > 0" class="pages">
      <Pagination
        :currentPage="videoCurrentPage"
        :total="videoTotal"
        :pageSize="pageSize" 
        mode="multiple"
        @getPageData="getPageVideos"
      ></Pagination>
    </div>
    <div class="select-all" @click="isCheckAll = !isCheckAll">
      <img v-if="isCheckAll" src="@/assets/icons/checkbox-yellow-checked.svg" alt="">
      <img v-else-if="isIntermediate" src="@/assets/icons/checkbox-yellow-minus.svg" alt="">
      <img v-else src="@/assets/icons/checkbox-white-square.svg" alt="">
      
      <span>{{ $t('video_select_all') }}</span>
      <div class="bar"></div>
      <span>{{ $t('video_selected')/*已勾選*/ }}：{{ checkCount }}</span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { getVideoDateRangeSetting } from '@/config/dateRange.js'
import multiDownload from 'multi-download'

export default {
  name: 'SearchBarVideo',
  components: {
    TreeSelect: () => import('@/components/Base/TreeSelect.vue'),
    DateRangeSelect: () => import('@/components/Base/DateRangeSelect.vue'),
    Pagination: () => import('@/components/Base/Pagination.vue'),
    TooltipElement: () => import('@/components/Base/TooltipElement.vue'),
  },
  data() {
    return {
      selectedDevices: [],
      selectedGroup: {}, // 選中的群組
      deviceGroupList: [], // 設備群組列表
      videoDateTime: [],
      maxRangeDays: 180, // 最大可選擇區間天數
      enabledDateStart: null, // 可以查詢開始時間
      enabledDateEnd: null, // 可以查詢結束時間

      deviceGroupProps: {
        children: 'children',
        label: 'label',
      },
    }
  },
  computed: {
    ...mapState(['permissionV2', 'groupList']),
    ...mapState('account', ['deviceTreeList']),
    // ...mapState('video', ['queryParams', 'searchLoading', 'videoList', 'videoEventsTotal', 'videoCurrentPage']),
    ...mapState('historyVideo', [
      'searchLoading',
      'pageSize', 
      'videoTotal',
      'videoCurrentPage',
      'historyVideoList',
    ]),
    checkCount() {
      return this.historyVideoList.filter(item => item.isCheck).length
    },
    isCheckAll: {
      get() {
        return this.historyVideoList?.length > 0 && this.checkCount === this.historyVideoList.length
      },
      set(val) {
        this.historyVideoList.forEach(item => {
          if (item.isCheck === undefined) this.$set(item, 'isCheck', val)
          else item.isCheck = val
        })
      }
    },
    isIntermediate() {
      return this.checkCount > 0 && this.checkCount < this.historyVideoList.length
    },
  },
  watch: {
    
  },
  async mounted() {
    // 0: private, 1: public
    await this.getDeviceTreeList({ public: 0 })
    
    const deviceData = JSON.parse(JSON.stringify(this.deviceTreeList))
    this.deviceGroupList = this.removeEmptyChildren(deviceData)
    this.initDateRange()
  },
  methods: {
    ...mapMutations('historyVideo', ['updateQueryParams']),
    ...mapActions('historyVideo', ['searchVideoList']),


    ...mapActions('video', ['getVideoList']),
    ...mapActions('account', ['getDeviceTreeList']),
    ...mapActions('historyFr', ['getFrEventsTotal', 'searchFrEvents', 'resetHistoryFrData']),
    initDateRange() {
      // 影片預設搜尋區間: 預設1週
      // 影片最大可搜尋區間: 預設最近三個月
      // 影片最多可搜尋的範圍: 預設最近1年
      const { defaultDateRange, maxRangeDays, enabledDateStart, enabledDateEnd } 
        = getVideoDateRangeSetting()
      this.videoDateTime = defaultDateRange
      this.maxRangeDays = maxRangeDays
      this.enabledDateStart = enabledDateStart
      this.enabledDateEnd = enabledDateEnd
    },
    setQueryParams() {
      let params = { 
        index: 0,  // 預設從第0筆開始
      }

      // 設備
      if (this.selectedDevices.length > 0) {
        params.postId = this.selectedDevices.map(device => device.id)
      }

      // 設備群組
      if (this.selectedGroup?.id) {
        params.group = [this.selectedGroup.id]
      }

      // 時間區間
      if (this.videoDateTime.length === 2) {
        params.startTime = new Date(this.videoDateTime[0]).toISOString()
        params.stopTime = new Date(this.videoDateTime[1]).toISOString()
      }
      
      // 將搜尋參數存入vuex
      this.updateQueryParams(params)
    },
    searchVideo() {
      // todo: 影片不能一次搜尋全部，要分頁去搜尋
      this.setQueryParams()
      this.searchVideoList({ page: 1 })
    },
    async getPageVideos(payload) {
      if (payload === 0) {
        this.$notify({
          title: this.$t('search_hint')/* 提示 */,
          message: '已在第一頁',
          type: 'warning'
        })
        return
      }
      
      // if (this.currentPage * this.pageSize < (payload - 1) * this.pageSize) {
      //   this.$notify({
      //     title: this.$t('search_hint')/* 提示 */,
      //     message: '無下一頁資料',
      //     type: 'warning'
      //   })
      //   return
      // }

      await this.searchVideoList({ page: payload })
    },
    earseSearchParams() {
      this.selectedDevices = []
      this.selectedGroup = {}
      this.initDateRange()
    },
    removeEmptyChildren(data) {
      return data.filter(node => {
        // 節點有children且長度不為0
        if (node.children && node.children.length > 0) {
            node.children = this.removeEmptyChildren(node.children)
            return true
        }
        return node.children
      })
    },
    downloadVideo() {
      const urls = this.historyVideoList.filter(item => item.isCheck).map(item => item.downloadUrl)
      multiDownload(urls)
    },
  }
}
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.search-bar-video {
  width: 100%;
  height: 186px;  
  padding: 16px 20px;
  background: #282942;
  color: #ffffff;
  border-bottom: 1px solid #ffffff33;
}

.search-bar {
  width: 100%;
  display: flex;
  align-items: flex-end;
  column-gap: 12px;
  margin-bottom: 16px;
  position: relative;

  .device {
    max-width: 15.6%;
    min-width: 15.6%;
    @include xxl {
      max-width: 20%;
      min-width: 20%;
    }
  }

  .dt-select {
    width: 26%;
    position: relative;
    @include xxl {
      width: 30%;
    }
  }

  .search-btn {
    width: 110px;
    height: 48px;
    border: 1px solid #FFC600;
    border-radius: 9px;
    background-image: url('../../assets/icons/feather-search.svg');
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    justify-content: center;  
    align-items: center;
    cursor: pointer;
  }
  .search-btn:hover {
    background: #FFC600;
    background-image: url('../../assets/icons/feather-search-w.svg');
    background-repeat: no-repeat;
    background-position: center center;
  }

  .loading {
    background: #FFC600;
  }

  .search-btn.loading:hover {
    background-image: url('../../assets/icons/feather-search.svg');
  }

  .ring {
    width: 30px;
    height: 30px;
    position: relative;
    color: inherit;
    display: inline-block;
    box-sizing: content-box;
    animation: ld-cycle 1s infinite linear;
  }

  .ring:after {
    content: " ";
    display: block;
    width: 60px;
    height: 60px;
    box-sizing: border-box;
    transform-origin: 0em 0em;
    transform: translateZ(0) scale(0.5);
    backface-visibility: hidden;
    border-radius: 50%;
    border: 7px solid #555555;
    border-left-color: transparent;
  }

  @keyframes ld-cycle
  {
    0% {
      animation-timing-function: cubic-bezier(0.3333,0.3333,0.6667,0.6667);
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .tool {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover, &.active {
      background: #4A5C7880;
      border-radius: 9px;
    }
    img {
      width: 32px;
      height: 32px;
      @include filter_FFF;
    }
  }

  .download-btn {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    top: 32px;
    color: #ffffff;
    background: #4A5C78;
    border-radius: 8px;
    padding: 0 16px;
    height: 48px;
    &.disabled {
      opacity: 0.2;
      cursor: not-allowed;
    }
    img {
      width: 18px;
      margin-right: 8px;
      @include filter_FFF;
    }
  }
}

.pages {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.select-all {
  display: flex;
  align-items: center;
  column-gap: 12px;
  font-size: px2rem(18);
  cursor: pointer;
  .bar {
    width: 1px;
    height: 20px;
    background: #ffffff80;
  }
}

</style>